<template>
  <div class="app flex-row align-items-center h-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="clearfix">
            <h4 class="pt-3">Du bist jetzt abgemeldet</h4>
            <p class="text-muted">Klicke <a href="https://reporting.msmloop.com/cockpit">here</a> um zurück zur Applikation zu gelangen.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignedOut",
};
</script>
