<template>
  <div class="app flex-row align-items-center h-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="clearfix">
            <h1 class="pt-3">Sign-in failed</h1>
            <p>Sorry, something went wrong while signing you in. Please be sure to use the following link when
               accessing the site: <a :href='mainURL'>{{ mainURL }}</a>.</p>
            <p>Click 'Try again' or the link above to repeat the sign in process.</p>
            <h4><a :href="mainURL">Try again</a></h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page401',

  computed: {
    mainURL: function() {
      return `${window.location.origin}${process.env.BASE_URL}`;
    }
  }
}
</script>
