<template>
  <div class="app flex-row align-items-center h-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="clearfix">
            <h4 class="pt-3">Please wait while signing in...</h4>
          </div>
        </div>
        <div class="col-md-1 inlineSpinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Callback'
}
</script>
