<template>
  <div class="app flex-row align-items-center h-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">401</h1>
            <h4 class="pt-3">Unauthorized</h4>
            <p class="text-muted">You are not authorized for the page you are looking for.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page401'
}
</script>
